import eventBus from "@/stuff/EventBus";
//import * as deepEqual from "deep-equal";

// eslint-disable @typescript-eslint/no-explicit-any

// We need a global, single value flag that we can check in window.onbeforeunload and beforeRouteLeave
// BUT we also need to be able to bind to a reactive property within the components!
// We can't really use Vuex (store) becuase that needs access to a component so the only
// way I can see is to have TWO flags - one here and one as a property on the current
// component, both with the same value :-(

// TODO - think up a better way!?

let myIsDirty: boolean = false;
let cleanFingerprint: Array<any> = [];

export interface IDirtynessOptions {
    model: any;
    isDeep: boolean;
    excludeList: Array<string>;
}

const isDebug = true;

// We could replace JSON with lodash.deepClone and lodash.isEqual if we need something more robust...
// ...JSON ignores functions (fine), is sensitive to order of properties (fine) but won't work for circular references, etc.
function buildFingerprint(model: any, isDeep: boolean, excludeList: Array<string>, values: Array<any>)  {
    if(model == null) {
        //console.log("buildFingerprint - model null or undefined");
        return;
    }
    for (const key in model) {
        if (model.hasOwnProperty(key) && excludeList.indexOf(key) === -1) {
            const item = model[key];
            if(item == null) {
                values.push(isDebug ? key + "=NULL" : null);
            }
            else if (Array.isArray(item)) {
                const arr = item as Array<any>;
                const typeOfFirst = arr.length > 0 ? typeof arr[0] : "undefined";
                if(typeOfFirst === "object") {
                    for (let i = 0; i < arr.length; i++) {
                        buildFingerprint(arr[i], isDeep, excludeList, values);
                    }    
                }
                else {
                    values.push(isDebug ? key + "=" + item.toString() : item);
                }
            }
            else if (isDeep && typeof item === "object" && key.substring(0, 1) !== "_") {
                buildFingerprint(item, isDeep, excludeList, values);
            }
            else if(typeof item !== "object") {
                values.push(isDebug ? key + "=" + item.toString() : item);
            }
        }
    }
}

function printFingerPrints(fp1: Array<any>, fp2: Array<any>, diffOnly: boolean) {
    let len = fp1.length;
    if(fp2.length > len) len = fp2.length;
    for(let i = 0; i < len; i++) {
        const val1 = i < fp1.length ? fp1[i] : "---";
        const val2 = i < fp2.length ? fp2[i] : "---";
        if(!diffOnly || val1 !== val2) console.log(`1: ${val1}    2: ${val2}`);
    }
}

//  NB this will return FALSE if things are in wrong order, e.g. arrays.
function areFingerPrintsEqual(fp1: Array<any>, fp2: Array<any>): boolean {
    const len = fp1.length;
    if(fp2.length !== len) {
        if(isDebug) console.log(`Dirtyness - fingerprint arrays are of unequal length: ${fp2.length} !== ${len}`);
        //printFingerPrints(fp1, fp2);
        return false;
    }
    for(let i = 0; i < len; i++) {
        if(fp1[i] !== fp2[i]) {
            if(isDebug) console.log(`Dirtyness - ${fp1[i]} !== ${fp2[i]}`);
            return false;
        }
    }
    return true;
}

const dirtyness = {
    get isDirty(): boolean {
        return myIsDirty;
    },
    // call this after load or save
    setClean(options: IDirtynessOptions): void {
        const wasDirty: boolean = myIsDirty;
        const fingerprint: Array<any> = [];
        buildFingerprint(options.model, options.isDeep, options.excludeList, fingerprint);
        cleanFingerprint = fingerprint;
        myIsDirty = false;
        if(wasDirty) {
            eventBus.$emit("dirtyness-changed", myIsDirty);
        }
    },
    // call this whenever anything changes (use a watcher)
    dataChanged(options: IDirtynessOptions): void {
        const wasDirty: boolean = myIsDirty;
        const fingerprint: Array<any> = [];
        buildFingerprint(options.model, options.isDeep, options.excludeList, fingerprint);
        myIsDirty = !areFingerPrintsEqual(fingerprint, cleanFingerprint);
        if(wasDirty !== myIsDirty) {
            if(isDebug) {
                //console.log("*** Dirtyness.dataChanged");
                //printFingerPrints(cleanFingerprint, fingerprint, true)
            }
            eventBus.$emit("dirtyness-changed", myIsDirty);
        }
    },
    // call this when we navigate away and abandon changes
    reset(): void {
        const wasDirty: boolean = myIsDirty;
        cleanFingerprint = [];
        myIsDirty = false;
        if(wasDirty) { 
            eventBus.$emit("dirtyness-changed", myIsDirty);
        }
    },
    defaultAuditFieldList(): Array<string> {
        return ["created", "createdByUserID", "lastUpdated", "lastUpdatedByUserID", "deleted", "deletedByUserID"];
    }
};

export default dirtyness;