
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator";
    import { Authentication } from "@/stuff/Authentication";
    import store from "@/store/store";
    import { reEstablishImpersonationMode } from "@/router/router";
    import * as toastr from "toastr";
    import { ISupplier } from "@/model/Supplier";
    import { IBuyer } from "@/model/Buyer";
    import { UserRole, UserStatus } from "@/model/Enums";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import ApiButton from "@/components/ApiButton.vue";
    import eventBus from "@/stuff/EventBus";

    @Component({ components: { ApiButton } })
    export default class SignedInLayout extends Vue {

        mounted() {
            document.body.style.backgroundColor = "#fff";

            // Have tried all sorts of ways of calling this in the router (with all of its events) but 
            // nothing fires if you just refresh the page so I added it here.. It now gets 
            // called everytime this gets loaded.  Boo.
            reEstablishImpersonationMode();

            console.log("signed in - mounted | isSupplier = " + this.isSupplier + "  | isBuyer = " + this.isBuyer);
            console.log("signed in - mounted - user = ", this.$store.state.signedInUser);

            const user = store.state.signedInUser;
            if(user && store.getters.isImpersonationMode) {
                if(store.getters.isBuyerMode) {
                    const buyerJson = sessionStorage.getItem("buyerData");
                    const buyerData: IBuyer|null = buyerJson ? JSON.parse(buyerJson) : null;
                    if(buyerData != null && buyerData.id === user.buyerID) {
                        this.buyerName = buyerData.description;
                    }
                    // We could maybe get data from server is sessionStorage is missing here...
                }
                else if(store.getters.isSupplierMode) {
                    const supplierJson = sessionStorage.getItem("supplierData");
                    const supplierData: ISupplier|null = supplierJson ? JSON.parse(supplierJson) : null;
                    // We could maybe get data from server is sessionStorage is missing here...
                }
            }

            // If user has not changed thier system-generated password yet, take user to profile page...
            const currentRoutePath = this.$router.currentRoute.path.toLowerCase();
            if (currentRoutePath.indexOf("/profile") === -1 && !user?.sso && user?.status !== UserStatus.HasChangedPassword) {
                toastr.warning("You should go to your profile and change the password from the system generated one you were sent.", "Please Change Your Password", {
                    timeOut: 40000, // leave it showing for a long time
                    closeButton: true,
                    onclick: () => {
                        if (currentRoutePath.indexOf("buyerzone") > -1) this.$router.push("/buyerZone/Profile");
                        else if (currentRoutePath.indexOf("supplierzone") > -1) this.$router.push("/supplierZone/Profile");
                        else this.$router.push("/Profile");
                    }
                });
            }

            eventBus.$on("supplier-trading-areas-updated", () => {
                this.checkTradingAreas();
            })

            this.fetchSupplierBannerText();
            this.fetchBuyerBannerText();
        }

        //
        // -- properties
        //

        get signedInUserName(): string { return store.getters.signedInUserName; }

        get isAdmin(): boolean { return store.getters.isAdminMode; }

        get isSupplier(): boolean { return store.getters.isSupplierMode; }

        get isBuyer(): boolean { return store.getters.isBuyerMode; }

        get isBuyerAdmin(): boolean { 
            if(!store.getters.isBuyerMode) return false; // not buyer or impersonating buyer...
            const user = store.state.signedInUser;
            return user?.role === UserRole.Admin || 
                user?.role === UserRole.Cqms || 
                user?.isBuyerAdminAccess === true;
        }

        get isNonCqmsAdmin(): boolean {
            const user = store.state.signedInUser;
            return user?.role === UserRole.Admin;
        }

        get isImpersonationMode(): boolean { return store.getters.isImpersonationMode; }

        buyerName: string = "(not set)";

        tradingAreasValid: boolean = false;

        get trainingLink(): string {
            return apiClient.resolveUrl("supplier/training")
        }

        supplierBannerMessage: string = "";
        buyerBannerMessage: string = "";

        //
        // -- watchers
        //

        @Watch("$route", { immediate: true, deep: true })
        private async onRouteChanged() {
            await Promise.all([
                this.checkTermsAgreed(),
                this.checkTradingAreas()
            ]);
        }

        //
        // -- methods
        //

        async signOut() {
            await Authentication.signOut();
            this.$router.push("/");
        }

        async downloadSsipCsv(event: Event) {
            const blob: Blob = await apiClient.get("api/report/ssipExtractCsv");
            utils.downloadBlob(document, blob, "SSIPExtract.csv");       
        }

        async userGuide() {
            const url = "api/file/userGuide";
            const blob: Blob = await apiClient.getNonJson(url);
            utils.downloadBlob(document, blob, "User Guide.pdf");
        }

        private async supplierUserGuide() {
            const url = "api/file/supplierUserGuide";
            const blob: Blob = await apiClient.getNonJson(url);
            utils.downloadBlob(document, blob, "User Guide.pdf");
        }

        private async checkTermsAgreed () {
            if (!store.getters.isSupplierMode) return;

            const response: boolean = await apiClient.get("api/terms/hasAgreed");
            if (!response) {
                this.$bvModal.show("termsModal");
            }
        }

        private async checkTradingAreas() {
            if (!this.isSupplier) {
                this.tradingAreasValid = true;
                return;
            }

            const response: boolean = await apiClient.get("api/supplier/tradingAreasValid");
            this.tradingAreasValid = response;
        }

        async downloadTerms() {
            const url = "api/terms/download";
            const blob: Blob = await apiClient.getNonJson(url);
            utils.downloadBlob(document, blob, "T&Cs")
        }

        async acceptTerms(event: Event) {
            const response: { message: string } = await apiClient.get("api/terms/agree", event);
            if (response.message != "ok") {
                toastr.warning("Failed");
                return;
            }
            this.$bvModal.hide("termsModal");
        }

        async fetchSupplierBannerText() {
            const response: string = await apiClient.get("api/supplier/banner");
            this.supplierBannerMessage = response;
        }

        async fetchBuyerBannerText() {
            const response: string = await apiClient.get("api/buyer/banner");
            this.buyerBannerMessage = response;
        }

    }
