import Vue from "vue";
import { EndSessionRequest, PublicClientApplication } from "@azure/msal-browser"
import store from "@/store/store";
import { User } from "@/model/User";
import utils from "@/stuff/Utils";

export module Authentication {

    export function isAuthenticated(): boolean { 
        return store.state.signedInUser !== null;
    }

    export function signedInUser(): User | null {
        return store.state.signedInUser;
    }

    // For JWT (JSON web token) 
    export function apiBearerToken(): string {
        const user = store.state.signedInUser;
        if (!user) return "";
        return user.jwtBearerToken;
    }

    export async function signOut(): Promise<void> {
        const user = store.state.signedInUser;

        await store.dispatch("setSignedInUser", null);
        sessionStorage.removeItem("signedInUser");

        if (user?.sso) {
            const msal = Vue.prototype.$msal as PublicClientApplication;
            const account = msal.getActiveAccount();

            if (account != undefined && account != null) {     
                const bases = document.getElementsByTagName("base");
                const baseUrl = bases.length > 0 ? bases[0].href : "/";       
                const request: EndSessionRequest = {
                    account: account,
                    postLogoutRedirectUri:  baseUrl
                }
                await msal.logoutRedirect(request);
            }
        }

        console.log("Authentication - signed out");
    }

    export async function signIn(user: User): Promise<void> {
        if (user && !utils.isEmptyId(user.id)) {
            // store in Vuex store - also sets session storage
            await store.dispatch("setSignedInUser", user);
        } else {
            await signOut();
        }
    }

    export async function onHttpUnauthorised(): Promise<void> {
        // see eventBus - http-401 is dealt with handler in App component...
        await store.dispatch("setSignedInUser", null);
    }
}