/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Authentication } from "./Authentication";
import moment from "moment";
import eventBus from "./EventBus";
import { ButtonToggler } from "./ButtonToggler";
import * as signalR from "@microsoft/signalr";
import { User } from "@/model/User";
import utils from "./Utils";

export interface ISendRequestParameters {
    path: string;
    postBody: any;
    resolve: (data: any) => void;
    reject: (error: any) => void;
    event?: Event;
    isJson?: boolean;
}

module apiClient {

    const bases = document.getElementsByTagName("base");
    export let baseUrl = bases.length > 0 ? bases[0].getAttribute("href") : "/";

    if(process.env.NODE_ENV === "development") {
        baseUrl = "http://localhost:58878/";
    }

    export function initialiseSignalR() {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(baseUrl + "pushhub")
            .build();

        // connection.on("send", (data: any) => {
        //     console.log("### SIGNALR - on send - data=", data);
        // });

        connection.on("ServerDataChanged", (what?: string, id?: number, userID?: number) => {
            console.log(`#-#-# SIGNALR - on ServerDataChanged - what=${what}  id=${id} user=${userID}`);
            if(what == null || id == null) return;

            // we're only interested if message originates from a DIFFERENT user!
            if(!Authentication.isAuthenticated) return;
            const loggedInUserID = (Authentication.signedInUser() as User).id;
            if(userID === loggedInUserID) return;
            
            // Send message on event bus...
            eventBus.$emit("server-data-changed", what, id, userID);
        });
        
        connection.start()
            .catch(function (err: Error) {
                return console.error(err.toString());
            });

        // connection.start()
        //     .then(() => connection.invoke("send", "Hello"))
        //     .catch(function (err: Error) {
        //         return console.error(err.toString());
        //     });
    }
    
    // recurse through response objects looking for ISO 8601 dates - and parse them
    export function processDates(data: any) {
        for (const item in data) {
            if (Object.prototype.hasOwnProperty.call(data, item)) {
                if (data[item] !== null) {
                    const type: string = typeof data[item];
                    if (type === "string") {
                        const txt = data[item] as string;
                        // seven or eight digit numbers are actually valid ISO 8601 formats - but we want to ignore those
                        if (isNaN(+txt)) {
                            const m = moment(txt, moment.ISO_8601, true);
                            if (m.isValid()) {
                                if (txt.substring(0, 4) === "0001") {
                                    data[item] = new Date(-62135596800000); // 01/01/0001 means 'no value'
                                }
                                data[item] = m.toDate(); // could leave as moment?
                            }
                        }
                    }
                    else if (type === "object") {
                        processDates(data[item]);
                    }
                }
            }
        }
    }

    export function resolveUrl(path: string): string {
        let url = baseUrl || "/";
        if(!url.endsWith("/")) url += "/";
        url += path.startsWith("/") ? path.substr(1) : path;
        return url;
    }

    export function sendRequest(parameters: ISendRequestParameters, authToken?: string) {
        const url = resolveUrl(parameters.path);
        const button = ButtonToggler.getButton(parameters.event);
        ButtonToggler.disableButton(button);
        const headers = new Headers({
            "Authorization": `Bearer ${authToken ?? Authentication.apiBearerToken()}`
        });
        if(parameters.isJson){
            headers.append("Content-Type", "application/json");
        }
        fetch(url, {
            method: parameters.postBody ? "POST" : "GET",
            credentials: "include", // can also be set to 'omit' or 'same-origin'
            headers: headers,
            body: parameters.postBody
        }).then((response: Response) => {

            //response.headers.forEach(h => console.log("** header: ", h));
            //console.log("#### request parameters = ", parameters);
            //console.log("#### response content type = ", response.headers.get("Content-Type"));
            if (response.status === 401) {
                // Assume session has timed out and emit an event to show the sign-in dialogue
                // (request will be re-issued using the parameters after successful sign-in.)
                ButtonToggler.enableButton(button); // hmm?
                Authentication.onHttpUnauthorised(); 
                eventBus.$emit("http-401", parameters); // trigger sign-in dialogue
                // note that we're NOT rejecting or resolving the promise at this stage!
                return null;
            }
            else if (response.status < 200 || response.status >= 300) {
                eventBus.$emit("http-error", response); // global handler (show message box or whatever)
                ButtonToggler.enableButton(button);
                parameters.reject(new Error("HTTP error " + response.status)); // handled where called
                return null;
            }
            else if (response.status === 204) {
                // empty response - this would cause json to fail so just return null
                console.log("Response 204 - no content");
                return null;
            }
            else if(response.headers.get("Content-Type")?.startsWith("application/json")) {
                return response.json();
            }
            else {
                

                let fileName = "";
                const disposition = response.headers.get("Content-Disposition");
                if (disposition && disposition.indexOf("attachment") !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) { 
                        fileName = matches[1].replace(/['"]/g, "");
                    }
                }
                console.log("disposition = ", disposition);
                console.log("filename = ", fileName);

                // this is for file downloads...
                return response.blob();
            }
        }).then((responseData: any) => {
            if (typeof responseData === "object" && responseData !== null && parameters.isJson) {
                //console.log("#### process dates");
                processDates(responseData);
            }
            ButtonToggler.enableButton(button);
            parameters.resolve(responseData);
        }).catch((error: Error) => {
            const isOnline = navigator.onLine;
            console.error(`Caught in ApiClient - isOnline: ${isOnline ? "yes" :"no" } - error: `, error);
            ButtonToggler.enableButton(button);
            if (isOnline) {
                eventBus.$emit("fetch-exception", error);
            }
            else {
                eventBus.$emit("fetch-failed-offline", error);
            }
            parameters.reject(error);
        });
    }

    function sendRequestPromise(path: string, postBody: any, event?: Event, isJson?: boolean, token?: string): Promise<any> {
        return new Promise((resolve: (data: any) => void, reject: (error: any) => void) => {
            sendRequest({ path, postBody, resolve, reject, event, isJson }, token);
        });
    }

    export function get(path: string, event?: Event, token?: string): Promise<any> {
        return sendRequestPromise(path, null, event, true, token);
    }

    export function post(path: string, data: any, event?: Event): Promise<any> {
        if(!(data instanceof FormData) && typeof data !== "string") {
            data = JSON.stringify(data);
        }
        return sendRequestPromise(path, data, event, true);
    }

    export function getNonJson(path: string, event?: Event): Promise<any> {
        return sendRequestPromise(path, null, event, false);
    }

    export function postNonJson(path: string, data: any, event?: Event): Promise<any> {
        return sendRequestPromise(path, data, event, false);
    }
}

export default apiClient;