
    import Vue from "vue";
    import Component from "vue-class-component";
    import eventBus from "./stuff/EventBus";
    import utils from "@/stuff/Utils";
    import apiClient, { ISendRequestParameters } from "@/stuff/ApiClient";
    import * as toastr from "toastr";
    import SignInDialogue from "./components/SignInDialogue.vue";

    const defaultLayout = "signed-out";
    
    @Component({
        components: { SignInDialogue }
    })
    export default class App extends Vue {

        private pendingApiParameters: ISendRequestParameters | null = null;

        mounted() {

            // Prevent any XSS shenanigans by default
            toastr.options.escapeHtml = true;

            eventBus.$on("http-401", (parameters: ISendRequestParameters) => {
                this.pendingApiParameters = parameters;
                console.log("...App component - setting promise and showing login dialogue");
                const dlg: SignInDialogue = this.$refs.signInDialogue as SignInDialogue;
                dlg.show();
            });

            eventBus.$on("http-error", (response: Response) => {
                //const contentType = response.headers.get("Content-Type");
                const status = response.status;

                if (status === 500) {
                    response.text().then(bodyText => {
                        if (bodyText) {
                            this.showErrorMessageWithContent("Server Error", "Status = " + response.status, bodyText);
                        }
                        else {
                            this.showErrorMessage("Server Error", "Status = " + response.status);
                        }
                    });
                    
                }
                else {
                    this.showErrorMessage("HTTP Error", "Status = " + response.status);
                }

                
            });

            eventBus.$on("fetch-exception", (error: Error) => {
                this.showErrorMessage("API Error", error.message ? error.message : "Failed to communicate with the server.");
            });
            eventBus.$on("fetch-failed-offline", (error: Error) => {
                this.showErrorMessage("Network Problem", "Could not access the server.\n\nPlease check your network connection.", "fa-wifi-slash" );
            });

            apiClient.initialiseSignalR();
        }

        onAuthenticated() {
            console.log("...App component - onAuthenticated");
            if (this.pendingApiParameters == null) {
                console.log("...App component - onAuthenticated - pendingApiParameters is **NULL**!");
                // what should we do if we ever get here?!!
                return;
            }            
            console.log("...App component - resend last API request");
            apiClient.sendRequest(this.pendingApiParameters);
            this.pendingApiParameters = null;
        }

        private messagesShowing: {[key: number]: number} = {};

        showErrorMessageWithContent(title: string, message: string, content: string, iconGlyph?: string) {
            const hash = utils.hashCode(`${title}|${message}|${content}`);
            if(this.messagesShowing[hash] != null && this.messagesShowing[hash] > 0) {
                // we're already showing this message
                this.messagesShowing[hash]++;
                return;
            }
            const ce = this.$createElement;
            const messageVNode = ce('div', {}, [
                ce('div', { class: ['errorMessageContainer'] }, [
                    ce('div', { class: [`fas ${iconGlyph ?? "fa-exclamation-circle"} errorMessageIcon`] }, []),
                    ce('div', { class: ['errorMessageText'] }, [message])
                ]),
                ce('div', { class: ['errorMessageContent'] }, [content])
            ]);

            this.messagesShowing[hash] = 1;
            this.$bvModal.msgBoxOk([messageVNode], {
                title: title,
                size: "xl",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "dismiss",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                centered: true
            }).then(value => { 
                delete this.messagesShowing[hash];
            }).catch(err => {
                delete this.messagesShowing[hash];
            });
        }

        showErrorMessage(title: string, message: string, iconGlyph?: string) {
            const hash = utils.hashCode(`${title}|${message}`);
            if(this.messagesShowing[hash] != null && this.messagesShowing[hash] > 0) {
                // we're already showing this message
                this.messagesShowing[hash]++;
                return;
            }

            const h = this.$createElement;
            const messageVNode = h('div', { class: ['errorMessageContainer'] }, [
                h('div', { class: [`fas ${iconGlyph ?? "fa-exclamation-circle"} errorMessageIcon`] }, []),
                h('pre', { class: ['errorMessageText'] }, [ message ])
            ]);
            this.messagesShowing[hash] = 1;
            this.$bvModal.msgBoxOk([messageVNode], {
                title: title,
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "dismiss",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                centered: true
            }).then(value => { 
                console.log("dismissing message (?)", this.messagesShowing[hash]);
                delete this.messagesShowing[hash];

            }).catch(err => {
                delete this.messagesShowing[hash];
            });
        }

        // computed property used to select layout - see also main.ts
        get layout() {
            return (this.$route.meta == undefined || this.$route.meta.layout || defaultLayout) + "-layout";
        }
    }
