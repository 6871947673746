/* eslint-disable  @typescript-eslint/no-explicit-any */
import moment from "moment";
import { ILookupItem, LookupItem } from "@/model/LookupItem";
import { Document as ModelDocument } from "@/model/Document";
import apiClient from "@/stuff/ApiClient";
import { IHsv, IRgb } from "@/model/Colour";

module utils {

    export const sleep = (milliseconds: number): Promise<any> => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    //
    // -- strings
    //

    export function isEmptyOrWhitespace(text: string|null) {
        if (!text) return true;
        // see if any non-whitespace
        return !(/\S/.test(text));
    }

    export function hashCode(text: string): number {
        let hash = 0;
        let chr = 0;
        for (let i = 0; i < text.length; i++) {
            chr = text.charCodeAt(i);
            // tslint:disable-next-line:no-bitwise
            hash  = ((hash << 5) - hash) + chr;
            // tslint:disable-next-line:no-bitwise
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    // Object.defineProperty(String.prototype, 'hashCode', {
    //     value: function() {
    //       var hash = 0, i, chr;
    //       for (i = 0; i < this.length; i++) {
    //         chr   = this.charCodeAt(i);
    //         hash  = ((hash << 5) - hash) + chr;
    //         hash |= 0; // Convert to 32bit integer
    //       }
    //       return hash;
    //     }
    //   });
    
    //
    // -- numbers
    //

    // to tell if user has actually entered a valid numeric value
    export function hasNumericValue(str: any): boolean {
        // d+ means it needs to have at least ONE digit
        // TODO - will currently fail if you enter ".5"
        return /^\d+\.?\d*$/.test(str);
    }

    //
    // -- GUIDs
    //

    export const emptyGuidValue = "00000000-0000-0000-0000-000000000000";

    // a GUID that is sortable in SQL server in date order
    export function newGuid(): string {
        function s4(): string {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        const dateHex = `000000000000${new Date().getTime().toString(16)}`;
        return `${s4()}${s4()}-${s4()}-4${s4().substr(1, 3)}-${s4()}-${dateHex.substr(dateHex.length - 12)}`;
    }

    export function isEmptyId(id: any): boolean {
        if (!id) return true;
        if (typeof id === "string") {
            return id === emptyGuidValue;
        }
        else if (typeof id === "number") {
            return id === 0;
        }
        return false;
    }

    export function isGuid(text: string): boolean {
        if (!text) return false;
        const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return pattern.test(text);
    }

    //
    // -- enums
    //

    // Case-insensitive string to number for enums
    // BEWARE - it assumes ZERO as default!
    export function parseEnum<E extends Enum<E>>(theEnum: E, value: string): number {
        if(!value) return 0;
        value = value.toLowerCase();
        const numberValues = Object.keys(theEnum).filter(value => isNaN(Number(value)) === false).map(value => +value) ;
        for (const numberValue of numberValues) {
            const stringValue = theEnum[numberValue].toLowerCase();
            if (stringValue === value) return numberValue;
        }
        return 0;
    }
    
    // https://stackoverflow.com/questions/50158272/what-is-the-type-of-an-enum-in-typescript
    type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

    export function enumToLookups<E extends Enum<E>>(theEnum: E): Array<LookupItem> {
        return Object.keys(theEnum)
            .filter(value => isNaN(Number(value)) === false)
            .map(num => new LookupItem({
                id: +num,
                description: theEnum[+num] as string,
                isArchived: false
            })) ;
    }

    export function camelCaseAddSpaces(text: string): string {
        if (!text) return "";
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    //
    // -- arrays (lookups)
    //

    // Lookups may contain archived items and possibly a default (none) item (or not)
    // For drop-down lists, we want a specific default item and to remove any archived items
    export function selectOptions(allItems: Array<ILookupItem>, defaultText?: string): Array<ILookupItem> {
        if(!defaultText) defaultText = "Please choose...";
        const defaultItem = new LookupItem({ id: 0, description: defaultText, isArchived: false } as ILookupItem);
        if(!allItems) return [ defaultItem ];
        return [defaultItem, ...allItems.filter(lu => lu.id > 0 && !lu.isArchived)];
    }

    export function lookupDescription(id: number|string, lookupList: Array<ILookupItem>, defaultText: string = "", failedText: string = ""): string {
        if (!failedText) failedText = `(id=${id}) opts=${(lookupList ? lookupList.length : (typeof lookupList) )}`;
        if (id === 0 || id === "" || id === emptyGuidValue) return defaultText;
        if (!Array.isArray(lookupList)) return "...";
        if (lookupList.length === 0) return "...";
        const item = lookupList.filter(l => l.id === id)[0];
        return item ? item.description : failedText;
    }

    //
    // -- dates
    //

    export const emptyDateValue = -62135596800000;

    export function whenText(d: any): string {
        const dte = moment(d);
        if (!dte.isValid() || dte.year() < 1753) { return "- - -"; }
        const daysDiff = moment().startOf("day").diff(moment(d).startOf("day"), "days");
        if (daysDiff < 0) { return "in the future"; }
        if (daysDiff === 1) { return "yesterday"; }
        if (daysDiff > 7) { return moment(d).format("DD/MM/YYYY"); } // <- client requested format
        if (daysDiff > 1) { return `${daysDiff} days ago`; }
        const hoursDiff = moment().diff(dte, "hours");
        if (hoursDiff === 1) { return "an hour ago"; }
        if (hoursDiff > 1) { return `${hoursDiff} hours ago`; }
        const minsDiff = moment().diff(dte, "minutes");
        if (minsDiff === 1) { return "a minute ago"; }
        if (minsDiff > 1) { return `${minsDiff} mins ago`; }
        return "just now";
    }

    export function dateText(d: any): string {
        const m = moment(d);
        if (!m.isValid() || m.year() < 1753) {
            return "- - -";
        }
        return moment(d).format("DD/MM/YYYY"); // <- client requested format
    }

    export function dateTimeText(d: any): string {
        const m = moment(d);
        if (!m.isValid() || m.year() < 1753) {
            return "- - -";
        }
        // http://momentjs.com/docs/#/displaying/
        return moment(d).format("DD/MM/YYYY HH:mm"); // <- client requested format
    }

    export function timeStampText(): string {
        return moment().format("YYYY-MM-DD-HH-mm"); 
    }

    export function isDate(d: any) {
        if (!d) return false;
        return !!d.getMonth;
    }

    export function hasDateValue(d: any) {
        return isDate(d) && (+(d)) > -6847804800000; // 1753 = min SQL date
    }

    export function isTodayOrFuture(d: any) {
        if(!isDate(d)) return false;
        const today = new Date();
        today.setHours(0,0,0,0)
        return d >= today;
    }

    export function areDatesAscending(date1: Date|null, date2: Date|null) {
        if(!hasDateValue(date1) || date1 == null) return true;
        if(!hasDateValue(date2) || date2 == null) return false;
        return date1 < date2;
    }


    export function today() {
        const today = new Date();
        today.setHours(2,0,0,0);  // It should be midnight but sod it, making it 2 AM will avoid any unfortunate time-zone crap...
        return today;
    }

    export function addDays(date: Date, days: number): Date {
        const newDate = new Date(date.valueOf());
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    }

    export function addYears(date: Date, years: number): Date {
        const newDate = new Date(date.valueOf());
        newDate.setFullYear(newDate.getFullYear() + years);
        return newDate;
    }

    //
    // -- stuff...
    //

    export function pad(value: number, width: number, padChar?: string) {
        padChar = padChar || "0";
        const text = new String(value);
        return text.length >= width ? text : new Array(width - text.length + 1).join(padChar) + text;
    }

    export function getWindowSize(): { height: number; width: number } {
        const win = window;
        const doc = document;
        const docElem = doc.documentElement;
        const body = doc.getElementsByTagName("body")[0];
        return {
            width: win.innerWidth || docElem.clientWidth || body.clientWidth,
            height: win.innerHeight|| docElem.clientHeight|| body.clientHeight
        };
    }

    export function iconUrl(document: ModelDocument | null | undefined): string {
        if(!(document?.hasFile)) return apiClient.resolveUrl("api/file/icon?extension=nul");
        const filename = document.filename;
        const dotAt = filename.lastIndexOf(".");
        const extension = dotAt === -1 || dotAt >= filename.length - 1 ? "" : filename.substr(dotAt + 1).toLowerCase();
        return apiClient.resolveUrl(`api/file/icon?extension=${extension}`);
    }

    export function debounce<F extends (...params: any[]) => void>(func: F, wait: number, immediate = false) {
        let timeout: number | undefined;
        //let timeout: NodeJS.Timeout | undefined;
        return function(this: any, ...args: any[]) {
            const later = () => {
                timeout = undefined;
                if (!immediate) func.apply(this, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait) as unknown as number | undefined;
            if (callNow) func.apply(this, args);
        } as F;
    }

    export function resetObject(obj: any) {
        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                const type = typeof obj[i];
                //if (obj[i] instanceof Guid) {
                //    obj[i] = Guid.createEmpty();
                //}
                if (Object.prototype.toString.call(obj[i]) === "[object Date]") {
                    obj[i] = null;
                }
                if (type === "object") {
                    // recursive call
                    resetObject(obj[i]);
                }
                else if (type === "number" || type === "bigint") {
                    obj[i] = 0;
                }
                else if (type === "string") {
                    obj[i] = "";
                }
                else if (type === "boolean") {
                    obj[i] = false;
                }
                else if (Array.isArray(obj[i])) {
                    obj[i] = [];
                }
            }
        }
    }

    export function hsvToRgb(hsv: IHsv): IRgb {
        let r: number = 0;
        let g: number = 0;
        let b: number = 0;

        const i: number = Math.floor(hsv.h * 6);
        const f: number = hsv.h * 6 - i;
        const p: number = hsv.v * (1 - hsv.s);
        const q: number = hsv.v * (1 - f * hsv.s);
        const t: number = hsv.v * (1 - (1 - f) * hsv.s);

        switch (i % 6) {
            case 0: r = hsv.v, g = t, b = p; break;
            case 1: r = q, g = hsv.v, b = p; break;
            case 2: r = p, g = hsv.v, b = t; break;
            case 3: r = p, g = q, b = hsv.v; break;
            case 4: r = t, g = p, b = hsv.v; break;
            case 5: r = hsv.v, g = p, b = q; break;
        }
        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255)
        };
    }

    export function escapeForHtml(unsafeText: string): string {
        return unsafeText
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");       
    }

    export function downloadBlob(document: Document, blob: Blob, overrideFilename?: string) {

        if(!(blob instanceof Blob)) {
            alert("Download failed - Unexpected data from server");
            return;
        }
        const maxSizeForBase64 = 1024 * 100; // not sure if this is really needed?
        const anchor = document.createElement("a");
        const windowUrl = window.URL || window.webkitURL;
        if (blob.size > maxSizeForBase64 && typeof windowUrl.createObjectURL === "function") {
            const fileUrl = windowUrl.createObjectURL(blob);
            anchor.download = overrideFilename ?? ""; 
            anchor.href = fileUrl;
            anchor.click();
            windowUrl.revokeObjectURL(fileUrl);
        }
        else {
            //use base64 encoding when less than set limit or file API is not available
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = () => {
                anchor.download = overrideFilename ?? "";
                if(typeof reader.result !== "string") {
                    alert("Download failed");
                    return;
                }
                anchor.href = reader.result;
                anchor.click();
            }
        }
    }

    export function toMoney(value: number, incPence: boolean = true): string {
        return incPence 
            ? "£" + (!isNaN(value) && value.toFixed(2) || "0.00")
            : "£" + (!isNaN(value) && value.toFixed(0) || "0");
    }
}

export default utils;