import { mapData } from "@/stuff/DataMapping";

export interface ILookupItem {
    id: number|string; // can be number or string (string for GUIDs)
    description: string;
    isArchived: boolean;
}

export class LookupItem implements ILookupItem {
    constructor(data?: ILookupItem) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ILookupItem) {
        mapData(data, { root: () => this });
    }

    id = 0; 
    description = "";
    isArchived = false;

    get isNew() {
        return !this.id;
    }
}