export interface IMappingTranslators {
    [key: string]: (data?: any) => any; // todo - is there a type that specifies a class instance?
}

function clone(src: any, target: any, options: IMappingTranslators) {
    // do we have an array wrapper with a translator function?
    const arrayTranslator = src != null && typeof src._translator === "function"
        ? src._translator
        : null;
    if(arrayTranslator !== null) src = src._array;

    // process all of the properties or array elements
    for (const i in src) {
        if (!src.hasOwnProperty(i)) continue;
        const type = typeof src[i];

        if (Array.isArray(src[i])) {
            const translator = options[i];
            target[i] = [];
            if(typeof translator === "function") {
                const arrayWrapper = {
                    _translator: translator,
                    _array: src[i]
                };
                clone(arrayWrapper, target[i], options);
            }
            else {
                clone(src[i], target[i], options);
            }
        }
        else if (src[i] === null) {
            target[i] = null; // becuase typeof null === "object"
        }
        else if (Object.prototype.toString.call(src[i]) === "[object Date]") {
            target[i] = new Date(src[i].getTime());
        }
        else if (type === "object") {
            // translator function can come from options or from arrayWrapper (parent, i.e. src, is array)
            const translator = options[i] || arrayTranslator;
            target[i] = typeof(translator) === "function"
                ? translator(src[i])
                : {};
            clone(src[i], target[i], options);
        }
        else {
            target[i] = src[i];
        }
    }
}

export function mapData<T>(source: Record<string, any>, options: IMappingTranslators): T {
    if(!options) {
        throw "options not supplied!";
    }
    if(typeof options.root === "function") {
        if(Array.isArray(source)) {
            const translator = options.root;
            const target: Array<any> = [];
            const arrayWrapper = {
                _translator: translator,
                _array: source
            };
            clone(arrayWrapper, target, options);
            return (target as unknown) as T;
        }
        else {
            const target = options.root(source);
            clone(source, target, options);
            return target as T;
        }
    }
    else {
        console.warn("root was not specified in options!");
        const target = Array.isArray(source) ? [] : {};
        clone(source, target, options);
        return target as T;
    }
}
