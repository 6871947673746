import Vue from "vue";
import { Validation } from "vuelidate";
import utils from "@/stuff/Utils";
import { LookupItem } from "@/model/LookupItem";

//
// -- dates
//

Vue.filter("dateTimeText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.dateTimeText(value);
});

Vue.filter("dateText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.dateText(value);
});

Vue.filter("whenText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.whenText(value);
});

//
// -- numbers
//

Vue.filter("currencyAmount", (value: number): string => {
    //return !isNaN(value) && value.toLocaleString(undefined, {maximumFractionDigits:2}) || "0.00";
    return utils.toMoney(value);
});

Vue.filter("integerCurrencyAmount", (value: number): string => {
    //return !isNaN(value) && value.toLocaleString(undefined, {maximumFractionDigits:2}) || "0.00";
    return utils.toMoney(value, false);
});

Vue.filter("fileSize", (bytes: number): string => {
    const decimals = 1; // can change this as required...
    let text = "0 bytes";
    if (bytes > 0) {
        const k = 1000; // or 1024 for KiB, MiB, etc.
        const dm = decimals + 1 || 3;
        const sizes = ["bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        text = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
    return text;
});

//
// -- boolean
//

Vue.filter("yesNo", (value: boolean): string => {
    return value ? "Yes" : "No";
});

//
// -- lookups
//

Vue.filter("lookup", (id: number|string, lookupList: Array<LookupItem>, defaultText: string = "", failedText: string = ""): string => {
    if (!failedText) failedText = `(id=${id}) opts=${(lookupList ? lookupList.length : (typeof lookupList) )}`;
    if (id === 0 || id === "" || id === utils.emptyGuidValue) return defaultText;
    if (!Array.isArray(lookupList)) return "...";
    if (lookupList.length === 0) return "...";
    const item = lookupList.filter(l => l.id === id)[0];
    return item ? item.description : failedText;
});

//
// -- validation
//

//Connect vuelidate to bootstrap-vue state
Vue.filter("validationState", (validation: Validation): boolean | null => {
    if (validation == null) return null;
    if (validation.$error) return false;
    return null;
});

Vue.filter("validationStateText", (validation: Validation): string => {
    if (validation == null) return "null";
    if (validation.$invalid && !validation.$error) return "inv no error";
    if (validation.$invalid) return "invalid";
    if (!validation.$error) return "no error";
    return "error";
});
