
    import Vue from "vue";
    import Component from "vue-class-component";

    @Component({})
    export default class BlankLayout extends Vue {
        mounted() {
            document.body.style.backgroundColor = "#f8f8f8";
        }
    }
