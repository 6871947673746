import Vue from "vue";
import Vuelidate from "vuelidate";
import BootstrapVue from "bootstrap-vue";
import InfiniteLoading from "vue-infinite-loading";
import LoadingThrobber from "vue-loading-overlay";
import VCalendar from "v-calendar";
import VueApexCharts from "vue-apexcharts";
import money from "v-money";
import { PublicClientApplication } from "@azure/msal-browser"; 
import VueTheMask, { mask } from "vue-the-mask";

import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";

import "@/stuff/CustomFilters";

// CSS

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "@fortawesome/fontawesome-pro/css/all.css"
import "toastr/build/toastr.css"; 
import "@/css/site.scss";
import "./css/vertical-tabs.css";

//
// -- Plugins
//

Vue.use(Vuelidate as any); // eslint-disable-line @typescript-eslint/no-explicit-any

Vue.use(BootstrapVue);

// https://peachscript.github.io/vue-infinite-loading/guide/configure-plugin-opts.html#props-settings
Vue.use(InfiniteLoading, {
    props: { spinner: "spiral" },
    slots: { noMore: "no more items", noResults: ""  }
});

Vue.use(LoadingThrobber);

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, { 
    title: 'MMMM YYYY'
});

Vue.use(VueApexCharts)
Vue.component("apexchart", VueApexCharts);

Vue.use(money)

Vue.use(VueTheMask);
Vue.directive(mask);

//
// -- stuff...
//

import Blank from "./layouts/Blank.vue";
import SignedOut from "./layouts/SignedOut.vue";
import SignedIn from "./layouts/SignedIn.vue";
import dirtyness from "@/stuff/Dirtyness";
import apiClient from "./stuff/ApiClient";
import { SsoConfig } from "./model/SsoConfig";

Vue.component("blank-layout", Blank);
Vue.component("signed-out-layout", SignedOut);
Vue.component("signed-in-layout", SignedIn);

Vue.config.productionTip = false;

// TODO
// https://www.raymondcamden.com/2019/05/01/handling-errors-in-vuejs
// https://stackoverflow.com/questions/52071212/how-to-implement-global-error-handling-in-vue

if(process.env.NODE_ENV !== "development") {
    Vue.config.errorHandler = (error: Error, vm: Vue, info: string) => {
        console.log("@@@@ Vue.config.errorHandler: " + error.toString() + " info:" + info);
        console.error(error);
        console.log("@@@@ stack:")
        console.log(error?.stack);
        
    };
    Vue.config.warnHandler = (message: string, vm: Vue, trace: string) => {
        console.log("@@@@ Vue.config.warnHandler: " + message + " trace:" + trace);
    };
    
    window.onerror = (message: string | Event, source: string | undefined, line: number | undefined, column: number | undefined, error: Error | undefined) => {
        console.log("@@@@ window.onerror: " + message);
        console.error(error);
        console.log("@@@ stack:")
        console.log(error?.stack);
    };    
}

// dirty-check - leaving page
window.addEventListener("beforeunload", (event?: Event) => {
    if(!event) event = window.event;
    if (dirtyness.isDirty) {
        if(event) {
            event.preventDefault();
            event.returnValue = true;
        }
        // I don't think messages like this are supported in modern browsers but...
        return "It looks like you have not saved your changes."; 
    }
    else {
        if(event){
            // From MDN docs: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete (event as any)["returnValue"];
        }
        // no real point in calling reset() since we're leaving the site but might as well...
        dirtyness.reset(); 
        return undefined;
    }
});

(async () => {
    //
    // -- vistry sso stuff 
    //

    const bases = document.getElementsByTagName("base");
    const baseUrl = bases.length > 0 ? bases[0].href : "/";

    const vistryConfig: SsoConfig = await apiClient.get("api/vistry/config");
    
    store.state.vistrySsoScope = vistryConfig.applicationScopeID;
    store.state.vistrySsoConfig.auth.authority = vistryConfig.authority;
    store.state.vistrySsoConfig.auth.clientId = vistryConfig.clientID;
    store.state.vistrySsoConfig.auth.redirectUri = baseUrl;

    Vue.prototype.$msal = new PublicClientApplication(store.state.vistrySsoConfig);

    //
    // -- Mount the application!
    //
    
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount("#app");
})()