import utils from "@/stuff/Utils";
import { UserRole, UserStatus } from "./Enums";
import { mapData } from "@/stuff/DataMapping";

export interface IUser {
    id: number;
    status: UserStatus;
    statusInfo: string;
    role: UserRole;
    buyerID: number;
    supplierID: number;
    regionID: number;
    isBuyerHmrcAccess: boolean;
    isBuyerAdminAccess: boolean;
    forename: string;
    surname: string;
    email: string;
    password: string;
    mainPhone: string;
    mobileNumber: string;
    isDormant: boolean;
    isAuditor: boolean;
    auditorModuleIDs: Array<number>;
    isWorkflowAdmin: boolean;
    lastLoggedIn: Date;
    prevLastLoggedIn: Date;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    jwtBearerToken: string;
    termsAgreed: Date;
    sso: boolean;
}

export class User implements IUser {

    constructor(data?: IUser) {
        if (data) this.update(data);
    }

    update(data: IUser) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    status: UserStatus = UserStatus.None;
    statusInfo: string = "";
    role: UserRole = UserRole.None;
    buyerID: number = 0;
    supplierID: number = 0;
    regionID: number = 0;
    isBuyerHmrcAccess: boolean = false;
    isBuyerAdminAccess: boolean = false;
    forename: string = "";
    surname: string = "";
    email: string = "";
    password: string = "";
    mainPhone: string = "";
    mobileNumber: string = "";
    isDormant: boolean = false;
    isAuditor: boolean = false;
    auditorModuleIDs: Array<number> = [];
    isWorkflowAdmin: boolean = false;
    lastLoggedIn: Date = new Date(utils.emptyDateValue);
    prevLastLoggedIn: Date = new Date(utils.emptyDateValue);
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    termsAgreed: Date = new Date(utils.emptyDateValue);
    sso: boolean = false;

    jwtBearerToken: string = "";

    get isNew() {
        return !this.id;
    }

    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }

    get fullname() {
        return this.forename + " " + this.surname;
    }

    get isSupplier() {
        return this.role === UserRole.Supplier;
    }

    get isBuyer() {
        return this.role === UserRole.Buyer;
    }

    get isCqmsOrSuperAdmin() {
        return this.role === UserRole.Cqms || this.role === UserRole.Admin;
    }
}