/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Vuex, { StoreOptions, MutationTree, ActionTree, ActionContext } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { User } from "@/model/User";
import utils from "@/stuff/Utils";
import apiClient from "@/stuff/ApiClient";
import { LookupItem } from "@/model/LookupItem";
import { ImpersonationMode, LookupGroup, UserRole } from "@/model/Enums";
import { SupplierSearchParameters } from "@/model/SupplierSearchParameters";
import { Configuration } from "@azure/msal-browser";

Vue.use(Vuex);

//
// -- This allows us to keep globally available data
//    that we can bind to in components...
//

// Define what we're storing
export interface IStoreState {
    vistrySsoConfig: Configuration;
    vistrySsoScope: string;
    signedInUser: User | null;
    companyTypeList: Array<LookupItem>;
    countryList: Array<LookupItem>;
    supplierStatusList: Array<LookupItem>;
    communicationTypeList: Array<LookupItem>;
    paymentTypeList: Array<LookupItem>;
    paymentMethodList: Array<LookupItem>;
    schemeNameList: Array<LookupItem>;
    userList: Array<LookupItem>;
    buyerList: Array<LookupItem>;
    supplierList: Array<LookupItem>;
    tradeList: Array<LookupItem>;
    tradingAreaList: Array<LookupItem>;
    scopeList: Array<LookupItem>;
    pendingApiCall: Promise<any> | null;
    impersonationMode: ImpersonationMode;
    moduleList: Array<LookupItem>;
    matrixParameters: SupplierSearchParameters | null;
    schemeList: Array<LookupItem>;
}

//
// -- build an options object
//

const getSignedInUserRole = (state: IStoreState) => (state.signedInUser !== null && !utils.isEmptyId(state.signedInUser.id)) ? state.signedInUser.role : UserRole.None;

const dataState = createPersistedState({
    // items kept in local storage
    paths: [
        "matrixParameters"
    ]
});

const options: StoreOptions<IStoreState> = { 
    plugins: [dataState],
    state: () => ({
        // shared state
        vistrySsoConfig: {
            auth: {
                clientId: "",
                authority: "",
                redirectUri: "",
                postLogoutRedirectUri: null,                
            },
            cache: {
                cacheLocation: "sessionStorage",
            }            
        },
        vistrySsoScope: "",
        signedInUser: null as User|null,
        companyTypeList: [] as Array<LookupItem>,
        countryList: [] as Array<LookupItem>,
        supplierStatusList: [] as Array<LookupItem>,
        paymentTypeList: [] as Array<LookupItem>,
        paymentMethodList: [] as Array<LookupItem>,
        schemeNameList: [] as Array<LookupItem>,
        userList: [] as Array<LookupItem>,
        buyerList: [] as Array<LookupItem>,
        supplierList: [] as Array<LookupItem>,
        tradeList: [] as Array<LookupItem>,
        tradingAreaList: [] as Array<LookupItem>,
        scopeList: [] as Array<LookupItem>,
        communicationTypeList: [] as Array<LookupItem>,
        pendingApiCall: null,
        impersonationMode: ImpersonationMode.None,
        moduleList: [] as Array<LookupItem>,
        matrixParameters: null,
        schemeList: [] as Array<LookupItem>
    }),
    getters: {
        // computed state
        isSignedIn: (state: IStoreState) => state.signedInUser !== null && !utils.isEmptyId(state.signedInUser.id),
        signedInUserName: (state: IStoreState) => (state.signedInUser !== null && !utils.isEmptyId(state.signedInUser.id)) ? state.signedInUser.fullname : "(not signed in)",
        signedInUserRole: getSignedInUserRole,
        isSupplierMode: (state: IStoreState): boolean => {
            const role = getSignedInUserRole(state);
            if(role === UserRole.Supplier) return true; // is a real supplier
            const canImpersonate = role === UserRole.Admin || role === UserRole.Cqms;
            return canImpersonate && state.impersonationMode === ImpersonationMode.Supplier;
        },
        isBuyerMode: (state: IStoreState): boolean => {
            const role = getSignedInUserRole(state);
            if(role === UserRole.Buyer) return true; // is a real buyer
            const canImpersonate = role === UserRole.Admin || role === UserRole.Cqms;
            return canImpersonate && state.impersonationMode === ImpersonationMode.Buyer;
        },
        isAdminMode: (state: IStoreState): boolean => {
            const role = getSignedInUserRole(state);
            const isAdmin = role === UserRole.Admin || role === UserRole.Cqms;
            return isAdmin && state.impersonationMode === ImpersonationMode.None;
        },
        isImpersonationMode: (state: IStoreState): boolean => {
            const role = getSignedInUserRole(state);
            const canImpersonate = role === UserRole.Admin || role === UserRole.Cqms;
            return canImpersonate && state.impersonationMode !== ImpersonationMode.None;
        },
        userRole: (state: IStoreState): UserRole => {
            return getSignedInUserRole(state);
        }
    },
    mutations: {},  // added below
    actions: {},    // added below
    modules: {}     // guess we don't need this...
};

// couple of shortcuts (and tell subsequent typescript they're *not* undefined)
const mutations = options.mutations as MutationTree<IStoreState>;
const actions = options.actions as ActionTree<IStoreState, IStoreState>;

//
// -- helper function to streamline the adding of lookups a little bit
//

function addLookup (
    mutations: MutationTree<IStoreState>, 
    actions: ActionTree<IStoreState, IStoreState>, 
    name: string, 
    stateProperty: string,
    url: string) {

    const mutationMethod = "set" + name + "List";
    const actionMethod = "load" + name + "List";
    mutations[mutationMethod] = (state: IStoreState, list: Array<LookupItem>) => {
        (state as any)[stateProperty] = list;
    };
    actions[actionMethod] = async (context: ActionContext<IStoreState, IStoreState>) => {
        const list = await apiClient.get(url);
        context.commit(mutationMethod, list);
    };
}

// Add all the "basic" lookups
// (I'm really not sure this is the right thing to do anymore...)
addLookup(mutations, actions, "Country",           "countryList",           `/api/lookup/search/${LookupGroup.Country}`);
addLookup(mutations, actions, "CompanyType",       "companyTypeList",       `/api/lookup/search/${LookupGroup.CompanyType}`);
addLookup(mutations, actions, "SupplierStatus",    "supplierStatusList",    `/api/lookup/search/${LookupGroup.SupplierStatus}`);
addLookup(mutations, actions, "PaymentType",       "paymentTypeList",       `/api/lookup/search/${LookupGroup.PaymentType}`);
addLookup(mutations, actions, "PaymentMethod",     "paymentMethodList",     `/api/lookup/search/${LookupGroup.PaymentMethod}`);
addLookup(mutations, actions, "SchemeName",        "schemeNameList",        `/api/lookup/search/${LookupGroup.SchemeName}`);
addLookup(mutations, actions, "User",              "userList",              "/api/user/lookups");
addLookup(mutations, actions, "Buyer",             "buyerList",             "/api/buyer/lookups?supplierID=0");
addLookup(mutations, actions, "Supplier",          "supplierList",          "/api/supplier/lookups");
addLookup(mutations, actions, "Trade",             "tradeList",             "/api/trade/lookups");
addLookup(mutations, actions, "TradingArea",       "tradingAreaList",       `/api/lookup/search/${LookupGroup.TradingAreas}`)
addLookup(mutations, actions, "Scope",             "scopeList",             "/api/scope/lookups");
addLookup(mutations, actions, "CommunicationType", "communicationTypeList", `/api/lookup/search/${LookupGroup.CommunicationType}`);
addLookup(mutations, actions, "Module",            "moduleList",            "/api/module/lookups");
addLookup(mutations, actions, "Scheme",            "schemeList",            "/api/lookup/schemes");

// used during signing back in
mutations.setPendingApiCall = (state: IStoreState, pendingApiCall: Promise<any>) => {
    state.pendingApiCall = pendingApiCall;
};
actions.setPendingApiCall = (context: ActionContext<IStoreState, IStoreState>, pendingApiCall: Promise<any>) => {
    console.log("STORE - action - setPendingApiCall");
    context.commit("setPendingApiCall", pendingApiCall);
};

// for admin and CQMS users to access site as a supplier or buyer
mutations.setImpersonationMode = (state: IStoreState, impersonationMode: ImpersonationMode) => {
    state.impersonationMode = impersonationMode;
};
actions.setImpersonationMode = (
        context: ActionContext<IStoreState, IStoreState>, 
        data: { mode: ImpersonationMode; id: number }) => {

    console.log("STORE - action - setImpersonationMode", data);

    const user = context.state.signedInUser;
    if(user == null) return;
    
    // this can be called in a couple of ways:
    //   - from Home.vue (???) where data.id will be set from URL query string
    //   - from router.ts in case page gets refreshed where data.id will be zero

    if(data.mode === ImpersonationMode.Buyer) {
        user.buyerID = data.id || user.buyerID || -1; // -1 to sabotage default behaviour in case id is missing
        console.log("updating storage (Buyer) - user:", user);
        context.commit("setSignedInUser", user);
        context.commit("setImpersonationMode", data.mode);
    }
    else if(data.mode === ImpersonationMode.Supplier) {
        user.supplierID = data.id || user.supplierID || -1; // -1 to sabotage default behaviour in case id is missing
        console.log("updating storage (Supplier) - user:", user);
        context.commit("setSignedInUser", user);
        context.commit("setImpersonationMode", data.mode);
    }     
};

// the current user - see @/stuff/Authentication module
mutations.setSignedInUser = (state: IStoreState, user: User) => {
    state.signedInUser = user;
};
actions.loadSignedInUser = (context: ActionContext<IStoreState, IStoreState>) => {
    // try and retrieve from session storage
    // (this allows sign-in to perist across tabs)
    const userJson = sessionStorage.getItem("signedInUser");
    if(!userJson) return;
    const user = new User(JSON.parse(userJson as string));
    context.commit("setSignedInUser", user);
};
actions.setSignedInUser = (context: ActionContext<IStoreState, IStoreState>, user: User) => {
    sessionStorage.setItem("signedInUser", JSON.stringify(user));
    context.commit("setSignedInUser", user);
};

mutations.setMatrixParameters = (state: IStoreState, parameters: SupplierSearchParameters) => {
    state.matrixParameters = parameters;
}
actions.setMatrixParameters = (context: ActionContext<IStoreState, IStoreState>, parameters: SupplierSearchParameters) => {
    context.commit("setMatrixParameters", parameters);
}

//
// -- the main deal - export the Store
//

export default new Vuex.Store<IStoreState>(options);