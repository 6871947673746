// Same as TriState in Visual Basic
export enum TriState {
    UseDefault = -2, // 0xFFFFFFFE
    True = -1, // 0xFFFFFFFF
    False = 0
}

export enum UserRole {
    None = 0,
    Admin = 1,
    Cqms = 2,
    Buyer = 3,
    Supplier = 4
}

export enum UserStatus {
    None = 0,
    InitialEmailPending = 1,
    InitialEmailSent = 2,
    InitialEmailFailed = 3,
    HasChangedPassword = 4
}

export enum LookupGroup {
    All = 0,
    CompanyType = 1,
    Country = 2,
    SupplierStatus = 3,
    CommunicationType = 4,
    PaymentType = 5,
    PaymentMethod = 6,
    SchemeName = 7,
    LegacyProgress = 8,
    HistoryType = 9,
    CdmCategory = 10,
    WorkflowCategories = 11,
    RejectedReasons = 12,
    TradingAreas = 13
}

export enum DocumentCategory {
    None = 0,
    SurveyResponse = 1,
    Supplier = 2,
    Communication = 3,
    SupplierStatus = 4,
    Invoice = 5
}

export enum DocumentVisibility {
    None = 0,
    Everyone = 1,
    Cqms = 2,
    Suppliers = 3,
    Buyers = 4
}

export enum SurveyQuestionType {
    NoResponse = 0,
    FreeText = 1,
    Checkboxes = 2,
    RadioButtons = 3,
    SingleLineText = 4,
    DatePicker = 5,
    Integer = 6,
    Money = 7,
    Evidence = 8,
    Matrix = 9
}

export enum SurveySectionType {
    Default = 0,
    TwoColumn = 1
}

export enum SurveyResponseOtherOptionType {
    None = 0,
    SingleLine = 1,
    MultiLine = 2,
    MultiDocument = 3,
    Evidence = 4
}

export enum ResponseStatus {
    None = 0,
    Hidden = 1,
    Entered = 2,
    Rejected = 3,
    Accepted = 4,
    ExemptFromApproval = 5
}

export enum ResponseSetStatus {
    None = 0,
    Legacy = 1,
    LegacyInProgress = 2,
    AwaitingCompletion = 3,
    Submitted = 4,
    Rejected = 5,
    Accepted = 6
}

export enum CertificateType {
    None = 0,
    SsipVerification = 1,
    CsrVerification = 2,
    SsipAssessment = 3,
    CsrAssessment = 4,
    CasAssessment = 5,
    PasAssessment = 6,
    PasVerification = 7,
    AdvancedAssessment = 8,
    AdvancedVerification = 9,
    VistryMaterialSuppliers = 10
}

export enum HistoryItemType {
    None = 0,
    Added = 1,
    FirstResponses = 2,
    StatusChanged = 3,
    AdHoc = 4,
    DeclarationConfirmed = 5,
    Dismissed = 6,
    Reassigned = 7
}

export enum ImpersonationMode {
    None = 0,
    Supplier = 1,
    Buyer = 2
}

export enum SupplierModuleGroupType {
    None = 0,
    AwaitingCompletion = 1,
    Rejected = 2,
    Submitted = 3,
    Accepted = 4,
    Expired = 5, 
    Legacy = 6
}

export enum FollowUpType {
    Payment = 1,
    History = 2,
    Communication = 3
}

export enum WorkflowView {
    Admin = 1,
    Auditor = 2
}

export enum ReportParameterType {
    None = 0,
    DateRange = 1,
    Switch = 2,
    Dropdown = 3
}